<template>
    <div class="header body">
    <VueSlickCarousel v-bind="settings" class="">
      <div class="steps-container tw-ml-8 tw-py-2 hotels">
            Choose hotels
      </div>
      <div class="separator ">
           <v-icon small class="chevron-right">mdi-chevron-right</v-icon>
      </div>
       <div class="steps-container tw-py-2 flights">
          Flights
      </div>
       <div class="separator">
           <v-icon small class="chevron-right">mdi-chevron-right</v-icon>
      </div>
       <div class="steps-container tw-py-2 activities">
          Activities
      </div>
       <div class="separator">
           <v-icon small class="chevron-right">mdi-chevron-right</v-icon>
      </div>
       <div class="steps-container tw-py-2 restaurants">
          Restaurants
      </div>
    </VueSlickCarousel>
     </div>
</template>

<script> 
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
export default {
  name: 'FlowBreadcrumbs',
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        'infinite': false,
        'slidesToShow': 1,
        'slidesPerRow': 5,
        'slidesToScroll': 1,
        'rows': 1,
        'centerMode': true,
        'centerPadding': '20px',
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/app.scss';
@import '@/assets/styles/variables.scss';
.steps-container {
    background-color: #F0F0F0;
    color: $title;
    font-family: 'SFProTextMedium';
    font-size: 0.75rem;
    border-radius: 30px;
    text-align: center;
    &:active {
        color: #7D7D84;
    }
}

.hotels {
    width: 30% !important;
}

.flights {
    width: 20% !important;
}

.activities {
    width: 25% !important;
}

.restaurants {
    width: 30% !important;
}

.separator {
    margin-left: 0.2rem;
    margin-right: 0.25rem;
    width: 5% !important;
}

.mdi-chevron-right {
  color: #7D7D84;
}


</style>
