var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MatchMedia',[_c('div',{staticClass:"card text-left tw-border tw-shadow tw-rounded-xl md:tw-rounded"},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"tw-relative"},[(_vm.type === 'restaurants')?[_c('RestaurantIcon')]:(_vm.type === 'activities')?[_c('ActivitiesIcon')]:_vm._e()],2)]),_c('v-img',{staticClass:"offer-img tw-rounded-t-xl md:tw-rounded tw-cursor-pointer",attrs:{"src":_vm.src},on:{"click":function($event){_vm.showPopup = true}}}),_c('div',{staticClass:"tw-flex tw-relative tw-flex-grow tw-overflow-hidden"},[_c('div',{staticClass:"tw-flex-grow left"},[_c('h4',{staticClass:"tw-p-0 tw-pl-0 tw-text-left"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"tw-font-montserrat-regular tw-text-base tw-text-paragraph tw-leading-6 description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-0"},[_c('div',{staticClass:"add-to-cart-button tw-inline-flex add-btn",class:_vm.type === 'restaurants' ? 'reserve-btn' : 'add-btn',on:{"click":function($event){_vm.type === 'activities' || _vm.type === 'restaurants'
                ? (_vm.showModal = true)
                : _vm.addItemToCart()}}},[_c('span',[_c('CartIcon',{staticClass:"tw-inline-block tw-h-3 tw-w-3 cart-icon"}),_c('span',{staticClass:"tw-inline-block tw-h-4 tw-leading-4"},[_vm._v(_vm._s(_vm.btnText))])],1)]),_c('div',{staticClass:"tw-text-right tw-font-poppins-semi-bold tw-text-title tw-font-semibold tw-flex-grow tw-leading-8 tw-h-8 price"},[(_vm.type !== 'restaurants')?[_c('div',[_vm._v(_vm._s(_vm.priceWithCurrency))]),_c('div',{staticClass:"per-adult"},[_vm._v("Per Adult")])]:[(_vm.price < 10)?_c('div',[_vm._v(" €"),_c('span',{staticClass:"tw-opacity-50"},[_vm._v("€€€")])]):(_vm.price < 35)?_c('div',[_vm._v(" €€"),_c('span',{staticClass:"tw-opacity-50"},[_vm._v("€€")])]):(_vm.price < 85)?_c('div',[_vm._v(" €€€"),_c('span',{staticClass:"tw-opacity-50"},[_vm._v("€")])]):_c('div',[_vm._v("€€€€")])]],2)])]),_c('transition',{attrs:{"name":"fade","appear":""}},[(_vm.showModal)?_c('div',{staticClass:"modal-overlay",on:{"click":function($event){_vm.showModal = false}}}):_vm._e()]),(_vm.showModal)?_c('date-modal',{attrs:{"item":_vm.item},on:{"close":_vm.closeModal,"update":_vm.updateModal}}):_vm._e()],1)],1),(_vm.showPopup)?_c('IframePopup',{attrs:{"src":this.url},on:{"togglePopup":() => {
        _vm.showPopup = !_vm.showPopup;
      },"addItemToCart":function($event){_vm.type === 'activities' || _vm.type === 'restaurants'
        ? (_vm.showModal = true)
        : _vm.addItemToCart();
      !_vm.showPopup;}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }