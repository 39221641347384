<template>
  <span>
    <v-card class="hotel-card-small card">
      <div class="icon">
        <div class="tw-relative">
          <AccommodationIcon />
        </div>
      </div>
      <div class="tw-relative">
        <v-img
          v-if="!imageError"
          @error="
            () => {
              imageError = true;
            }
          "
          class="tw-cursor-pointer offer-img"
          :src="hotel.image.path"
          @click="handleClick"
        ></v-img>
        <v-img
          v-else-if="hotel.hotelImage"
          class="tw-cursor-pointer offer-img"
          :src="hotel.hotelImage.path"
          @click="handleClick"
        ></v-img>
        <v-img
          v-else
          class="tw-cursor-pointer offer-img"
          :src="hotel.images[0].path"
          @click="handleClick"
        ></v-img>
      </div>
      <div class="tw-flex tw-flex-grow lower">
        <!-- left side -->

        <div class="tw-flex-grow left">
          <div>
            <!-- <v-rating
              :value="hotel.categoryName"
              color="#D62A53"
              dense
              half-increments
              readonly
              class="rating"
              background-color="#EB94A9"
              size="20"
              empty-icon="$ratingFull"
            ></v-rating> -->

            <v-card-title style="padding: 0" @click="handleClick">
              <div
                class="
                  tw-font-poppins
                  tw-font-semibold
                  tw-text-title
                  tw-font-semibold
                  tw-p-0
                  tw-cursor-pointer
                  tw-pl-0
                  tw-text-left
                  hotel-name
                "
              >
                {{ this.truncate(this.hotel.name, 15) }}
              </div>
            </v-card-title>
          </div>
          <v-card-text classs="tw-cursor-pointer" @click="handleClick">
            <review-rating :hotel="hotel" />
          </v-card-text>
        </div>

        <!-- right side -->
        <div class="tw-flex tw-flex-col tw-min-w-max w-24">
          <div
            class="tw-flex tw-flex-grow tw-flex-col tw-cursor-pointer"
            @click="handleClick"
          >
            <div
              class="
                tw-text-right
                price
                tw-font-semibold tw-text-2xl tw-leading-8
              "
            >
              <template v-if="this.hotel.sellingRate">
                {{
                  this.hotel.currency.replace('USD', '$').replace('EUR', '€') +
                  roundToTwo(this.hotel.sellingRate / this.nightsCount)
                }}
              </template>
              <template v-else>
                {{
                  this.hotel.currency.replace('USD', '$').replace('EUR', '€') +
                  roundToTwo(this.hotel.net / this.nightsCount)
                }}
              </template>
            </div>
            <div class="tw-text-right per-night">Per Night</div>
          </div>
          <!-- 
          <div class="room" @click="handleClick">
            {{ this.hotel.roomName }} {{ ' ' + this.hotel.boardCode }}
          </div> -->
          <div class="refund" @click="handleClick">
            <template v-if="isCancellationPolicy">
              Free cancellation until {{ this.hotel.cancellationPolicy }}
            </template>

            <template v-else>No refund available</template>
          </div>
          <!-- <div class="tw-inline-flex add-btn" @click="addToCart"> -->
          <div
            class="tw-inline-flex add-btn"
            @click="showAvailabilityPopup = true"
          >
            <span>
              <CartIcon class="tw-inline-block tw-h-4 tw-w-4 tw-mr-3" />
              <span class="tw-inline-block tw-h-4 tw-leading-4"
                >Add to cart</span
              >
            </span>
          </div>
        </div>
      </div>
    </v-card>
    <div v-if="showAvailabilityPopup" class="availability-popup-container">
      <div class="availability-popup">
        <v-btn @click="showAvailabilityPopup = false" class="cancel-btn">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.125 4.875L4.875 14.125"
              stroke="black"
              stroke-width="1.54167"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.875 4.875L14.125 14.125"
              stroke="black"
              stroke-width="1.54167"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>

        <div class="mainTitle">Add Accommodation</div>
        <div class="user-input-container date-input-container">
          <div>
            <DatePicker
              v-model="checkInDate"
              :min="minCheckIn"
              label="CHECK-IN"
              :rules="[dateFromRules.compareDates(checkInDate, checkOutDate)]"
            />
          </div>
          <div>
            <DatePicker
              v-model="checkOutDate"
              :min="checkInDate"
              label="CHECK-OUT"
              :rules="dateCheckOutRules"
            />
          </div>
        </div>
        <div class="user-input-container">
          <div>
            <Select
              :values="roomsCounts"
              :value="roomsCount"
              @update="roomsCount = $event"
              label="ROOMS"
            />
          </div>
          <div>
            <Select
              :values="adultsCounts"
              :value="adultsCount"
              @update="adultsCount = $event"
              label="ADULTS"
            />
          </div>
          <div>
            <Select
              :values="childrenCounts"
              :value="childrenCount"
              @update="childrenCount = $event"
              label="CHILDREN"
            />
          </div>
        </div>
        <div class="user-input-container children">
          <div
            class="tw-contents"
            v-for="(item, index) in childrenAges"
            :key="item + index"
          >
            <select-age
              :values="childAge"
              :value="paxes[index].age"
              @update="
                ($event => {
                  paxes[index].age = $event;
                  checkAvailability();
                })($event)
              "
              :rules="ageRules"
              :label="`AGE CHILD ${index + 1}`"
            />
          </div>
        </div>
        <div class="message">{{ message }}</div>
        <div v-if="message.length < 1" class="updated-net">
          <div>Per Night</div>
          <div>
            <template v-if="updatedDataHotel.sellingRate">
              {{
                updatedDataHotel.currency
                  .replace('USD', '$')
                  .replace('EUR', '€') +
                roundToTwo(updatedDataHotel.sellingRate / this.nightsCount)
              }}
            </template>
            <template v-else>
              {{
                updatedDataHotel.currency
                  .replace('USD', '$')
                  .replace('EUR', '€') +
                roundToTwo(updatedDataHotel.net / this.nightsCount)
              }}
            </template>
          </div>
        </div>
        <div v-if="message.length < 1" class="total updated-net">
          <div>Total</div>
          <div>
            <template v-if="updatedDataHotel.sellingRate">
              {{
                updatedDataHotel.currency
                  .replace('USD', '$')
                  .replace('EUR', '€') +
                roundToTwo(updatedDataHotel.sellingRate)
              }}
            </template>
            <template v-else>
              {{
                updatedDataHotel.currency
                  .replace('USD', '$')
                  .replace('EUR', '€') + roundToTwo(updatedDataHotel.net)
              }}
            </template>
          </div>
        </div>
        <div class="action-bar">
          <v-btn
            color="primary"
            class="proceed-btn"
            :disabled="updatedDataHotel === null"
            @click="addToCart"
          >
            Reserve
          </v-btn>
        </div>
      </div>
    </div>
    <PickedHotelDetails
      v-if="showing"
      :hotel="hotel"
      @close="
        () => {
          showing = false;
        }
      "
      @addToCard="
        () => {
          showAvailabilityPopup = true;
        }
      "
    />
  </span>
</template>

<script>
// import GoodIcon from '@/assets/inspirationPage/good.svg';
import CartIcon from '@/assets/inspirationPage/cart.svg';
import DatePicker from '@/views/inspiration/tab-items/components/DatePicker.vue';
import { diffDays } from '@/utils/appDate.js';
// import FlatButton from '../../FlatButton.vue';
import ReviewRating from '@/views/inspiration/tab-items/components/ReviewRating.vue';
//import PickedHotelDetails from '@/views/inspiration/tab-items/components/PickedHotelDetails.vue';
import PickedHotelDetails from '@/views/mobilePages/Packages/Steps/Hotels/MobileHotelDescription.vue';
import AccommodationIcon from '@/assets/inspirationPage/tabicons/accommodation_w.svg';
import Select from '@/views/inspiration/tab-items/components/Select.vue';
import SelectAge from '@/views/inspiration/tab-items/components/SelectAge.vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    loading: false,
    selection: 1,
    showAvailabilityPopup: false,
    imageError: false,
    checkInDate: moment().add(30, 'd').format('YYYY-MM-DD'),
    checkOutDate: moment().add(33, 'd').format('YYYY-MM-DD'),
    minCheckIn: '',
    showing: false,
    dateFromRules: {
      compareDates(checkInDate, checkOutDate) {
        this.isValid = new Date(checkInDate) <= new Date(checkOutDate);
        return (
          new Date(checkInDate) <= new Date(checkOutDate) ||
          'Date check-in must be less than date check-out'
        );
      },
    },
    textRules: [ v => !!v || 'Field is required' ],
    ageRules: [ v => parseInt(v) > 0 || 'Is required' ],
    dateCheckOutRules: [ v => !!v || 'Check-out date is required' ],
    nightsCounts: [ 1, 2, 3, 4, 5, 6, 7, 8 ],
    roomsCounts: [ 1, 2, 3, 4, 5, 6, 7, 8 ],
    adultsCounts: [ 1, 2, 3, 4, 5, 6, 7, 8 ],
    childrenCounts: [ 0, 1, 2, 3, 4 ],
    childAge: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 ],
    roomsCount: 1,
    adultsCount: 1,
    childrenCount: 0,
    nightsCount: 3,
    updatedDataHotel: null,
    message: '',
  }),
  props: [ 'hotel', 'loadPickedHotel' ],
  components: {
    CartIcon,
    AccommodationIcon,
    ReviewRating,
    Select,
    DatePicker,
    SelectAge,
    PickedHotelDetails,
  },
  computed: {
    ...mapGetters([ 'accommodationsInCart' ]),
    isCancellationPolicy: function() {
      return (
        new Date(this.hotel.cancellationPolicies[0].from).getDate() >=
        new Date().getDate()
      );
    },
    childrenAges: function() {
      return new Array(this.childrenCount).fill('');
    },
    paxes: function() {
      return new Array(this.childrenCount)
        .fill('')
        .map(() => ({ type: 'CH', age: 0 }));
    },
  },
  created() {
    this.checkAvailability();
  },
  watch: {
    showAvailabilityPopup: function() {
      if (this.showAvailabilityPopup) {
        document.querySelector('html')?.classList.add('modal-open');
      } else {
        document.querySelector('html')?.classList.remove('modal-open');
      }
    },
    showing: function() {
      if (this.showing) {
        document.querySelector('html')?.classList.add('hotel-modal-open');
      } else {
        document.querySelector('html')?.classList.remove('hotel-modal-open');
      }
    },
    nightsCount: function(newValue) {
      this.nightsCounts =
        newValue < 10
          ? new Array(10).fill(1).map((it, i) => i + 1)
          : new Array(newValue + 1).fill(1).map((it, i) => i + 1);
      this.checkAvailability();
    },
    checkInDate: function(newValue) {
      const dif = diffDays(this.checkOutDate, newValue);
      this.nightsCount = dif > 0 ? dif : 1;
      this.isValid = new Date(newValue) <= new Date(this.checkOutDate);
      this.checkAvailability();
    },
    checkOutDate: function(newValue) {
      const dif = diffDays(newValue, this.checkInDate);
      this.nightsCount = dif > 0 ? dif : 1;
      this.isValid = new Date(this.checkInDate) <= new Date(newValue);
      this.checkAvailability();
    },
    roomsCount: function() {
      this.checkAvailability();
    },
    adultsCount: function() {
      this.checkAvailability();
    },
    childrenCount: function() {
      this.checkAvailability();
    },
  },
  methods: {
    checkAvailability: async function() {
      this.message = 'loading...';

      try {
        this.updatedDataHotel = await this.loadPickedHotel({
          from: this.checkInDate,
          to: this.checkOutDate,
          nightsCount: this.nightsCount,
          roomsCount: this.roomsCount,
          adultsCount: this.adultsCount,
          childrenCount: this.childrenCount,
          paxes: this.paxes,
        });
        this.message = '';
      } catch {
        this.updatedDataHotel = null;
        this.message = 'There was nothing available for your search';
      }
    },
    ...mapActions([ 'addExperienceToCart', 'roomsChecking' ]),
    getImage: name => require('@/assets/inspirationPage/' + name),
    addToCart: async function() {
      this.message = '';
      if (!this.accommodationsInCart.length) {
        let rates;
        if (this.updatedDataHotel.rateType === 'RECHECK') {
          rates = await this.roomsChecking({
            rooms: [ { rateKey: this.updatedDataHotel.rateKey } ],
          });
        }
        if (rates) {
          this.updatedDataHotel.net = rates.net;
          this.updatedDataHotel.rateKey = rates.rateKey;
          this.updatedDataHotel.rateType = rates.rateType;
          this.updatedDataHotel.sellingRate = rates.sellingRate;
        }
        this.addExperienceToCart({
          id: this.updatedDataHotel.rateKey,
          name: this.updatedDataHotel.name,
          price: this.updatedDataHotel.net,
          image: this.updatedDataHotel.image.path,
          quantity: 1,
          type: 'accommodation',
          details: {
            destination: this.userDestination,
            checkInDate: this.checkInDate,
            checkOutDate: this.checkOutDate,
            nightsCount: this.nightsCount,
            roomsCount: this.roomsCount,
            adultsCount: this.adultsCount,
            childrenCount: this.childrenCount,
            paxes: this.paxes,
            ...this.updatedDataHotel,
          },
        });
        this.$store.commit('toggleDrawer', true);
        this.showAvailabilityPopup = false;
        return true;
      } else {
        this.message = 'Error: You already have an accommodation in your cart';
        return false;
      }
    },
    truncate: function(str, n) {
      return str.length > n ? str.substr(0, n) + '...' : str;
    },
    handleClick: function() {
      // this.$emit('openHotel', this.hotel);
      this.showing = true;
    },
    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2');
    },
  },
};
</script>

<style scoped lang="scss">
.v-card__title {
  padding: 10px;
  padding-left: 0px;
}

.v-card__text {
  padding-left: 0;
}

.text-data {
  line-height: 0rem;
}
.provisor {
  height: 17px;
}

.v-rating {
  text-align: start;
  margin-left: -2px;
}

.v-card {
  border: none;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06) !important;
  border-radius: 18px;
  max-width: 520px;
  display: flex;
  flex-direction: column;
}

.v-card > div:last-child {
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
}

.v-image {
  border-radius: 18px 18px 0px 0px;
  max-width: 520px;
  height: 16.35vw;
  max-height: 332px;
}

.flat .v-btn {
  background: transparent;
}

.hotel-name {
  display: block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: Poppins;
  font-style: normal;
  max-width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.04em;
  color: #28262e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.add-btn {
  width: 135px;
  height: 28px;
  left: 845px;
  top: 914px;
  background: #d62a53;
  border-radius: 90px;
  display: flex;
  transition: 0.3s;
  cursor: pointer;
  font-family: MontserratSemiBold;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;

  svg {
    height: 14px;
    width: 14px;
  }

  & > * {
    margin: auto;
  }
}

.add-btn:hover {
  opacity: 0.9;
}
.add-btn:active {
  opacity: 0.75;
}
.card {
  position: relative;
  border: none;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06) !important;
  border-radius: 18px;

  @media (min-width: 1060px) {
    max-width: 297.66px;
    // width: 297.66px;
  }
  min-height: 328.75px;
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 530px;
  h4 {
    font-family: PoppinsSemiBold;
    font-weight: 600;
    font-size: 17.0092px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #28262e;
    flex-flow: nowrap;
    white-space: nowrap;
  }

  .description {
    font-family: MontserratRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 11.3394px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #747378;
  }

  h4,
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
  }
}

.offer-img {
  @apply tw-w-full tw-object-cover;
  height: 175.76px;
  min-height: 175.76px;
  border-radius: 18px 18px 0px 0px;

  @media (min-width: 1060px) {
    max-width: inherit;
    width: inherit;
  }
}

.modal-overlay {
  @apply tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-bg-black tw-opacity-80;
  z-index: 98;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  max-width: 512px;
  background-color: #fff;
  border-radius: 16px;
  padding: 25px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.v-rating button {
  padding-left: 0 !important;
}
.rating {
  max-width: 114px;
}
.add-to-cart-button {
  font-family: MontserratSemiBold;

  font-size: 11.3394px;
  line-height: 11px;
  letter-spacing: -0.02em;
  color: #d62a53;
}

.cart-icon {
  margin-right: 8.51px;
}
.price {
  letter-spacing: -0.02em;
  height: 26.25px;
  color: #28262e;
  font-family: PoppinsSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  text-align: right;
  letter-spacing: -0.02em;

  color: #28262e;
}

.left {
  max-width: calc(100% - 135px);
  width: calc(100% - 135px);
  min-width: calc(100% - 135px);
}

.per-adult {
  font-family: MontserratRegular;
  font-size: 11.4839px;
  line-height: 11px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #747378;
  font-weight: medium;
}

.icon {
  z-index: 1;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #d62a53;
  border-radius: 50%;
  left: -7px;
  display: flex;
  top: -7px;
  padding: 0 !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  div {
    width: 32px;
    height: 32px;
  }

  svg {
    color: white;
    margin: auto;
    width: 20px;
    height: 20px;
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
  }
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.refund {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #747378;
  padding-bottom: 12px;
}

.room {
  font-family: MontserratRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #28262e;
  width: 107px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: auto;
  overflow: hidden;
}

.per-night {
  font-family: MontserratMedium;
  font-style: normal;
  font-size: 11.4839px;
  line-height: 11px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #747378;
}

.lower {
  padding: 24px;
}

.availability-popup-container {
  z-index: 2000 !important;
  position: fixed !important;
  inset: 0px !important;
  overflow: hidden !important;
  background: rgba(34, 34, 34, 0.6) !important;
  display: flex;
  padding: 40px !important;
  animation: 0.3s ease-out 0s 1 overlay;
}

.availability-popup {
  background: rgb(255, 255, 255) !important;
  position: relative !important;
  width: 100vw !important;
  max-height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  max-width: 512px !important;
  border-radius: 12px !important;
  margin: 0 auto;
  animation: 0.3s ease-out 0s 1 slideIn;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.3s;
}

.availability-popup::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.availability-popup::-webkit-scrollbar-thumb {
  background: transparent;
}

@keyframes overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}

.trip-basket-container {
  animation: 0.3s ease-out 0s 1 slideIn;
}

.action-bar {
  align-items: flex-end;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.cancel-btn,
.proceed-btn {
  border-radius: 104px !important;
  font-family: MontserratSemiBold;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  box-shadow: none !important;
}

.proceed-btn {
  width: calc(100% - 48px);
  margin: 24px;
  height: 54px !important;
}

.cancel-btn {
  border-radius: 104px !important;
  text-transform: none;
  margin: 0px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: none;
  min-width: 50px !important;
  width: 50px !important;
  min-height: 50px !important;
  height: 50px !important;
  margin-right: 16px !important;
  margin-left: auto;
  margin-top: 16px !important;
  background: transparent !important;
}

.mainTitle {
  font-family: PoppinsSemiBold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #28262e;
  margin-top: -12px;
  margin-bottom: 12px;
}

.user-input-container {
  width: 100%;

  > div {
    border: 1px solid rgb(176, 176, 176) !important;
    margin: 16px 24px;
    width: calc(100% - 48px);
    border-radius: 12px;
  }
}

.date-input-container {
  display: flex;
  padding: 16px 24px 0 24px;
  > div {
    flex: 50%;
    margin: 0;
  }
  > div:first-of-type {
    border-right-width: 0.5px !important;
    border-radius: 12px 0 0 12px;
  }
  > div:last-of-type {
    border-radius: 0 12px 12px 0;
    border-left-width: 0.5px !important;
  }
}

.children {
  width: calc(100% - 40px);
  padding-right: 8px;
}

.message {
  padding-top: 23.5px;
}

.updated-net {
  display: flex;
  width: calc(100% - 90px);

  > div:first-of-type {
    text-decoration: underline;
  }
  > div:last-of-type {
    margin-left: auto;
  }
}

.total {
  margin-top: 16px;
}
</style>
