<template>
  <Transition name="fade">
    <div
      v-if="showing"
      class="
        tw-fixed
        tw-inset-0
        tw-w-full
        tw-flex
        tw-items-center
        tw-justify-center
        tw-bg-semi-75
        tw-z-10
        tw-overflow-hidden
        modal-container
      "
      @click.self="close"
    >
    
      <div
        class="
          inner
          tw-w-full
          lg:tw-max-w-5xl
          tw-bg-white
          tw-shadow-lg
          tw-relative
          tw-z-10
        "
      >   
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  components: {
  },
  props: {
    showing: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  },
  watch: {
    showing(value) {
      if (value) {
        return document
          .querySelector('.v-main')
          .classList.add('tw-overflow-hidden');
      }

      document.querySelector('.v-main').classList.remove('tw-overflow-hidden');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.bg {
  background: rgba(0, 0, 0, 0.3);
}

.modal-container {

  background: rgba(0, 0, 0, 0.8);
  overflow-x: hidden;
  & > * {
    max-height: calc(100% - 24px);
    @media (max-width: 1089px) {
      max-height: calc(100%);
      border-radius: 0;
      overflow-x: hidden;
    }
  }
}

.inner {
  overflow-y:auto;
  border-radius: 4px;
}
</style>
