<template>
  <MatchMedia>
    <div class="card text-left tw-border tw-shadow tw-rounded-xl md:tw-rounded">
      <div class="icon">
        <div class="tw-relative">
          <template v-if="type === 'restaurants'"> <RestaurantIcon /></template>
          <template v-else-if="type === 'activities'"
            ><ActivitiesIcon />
          </template>
        </div>
      </div>
      <v-img
        :src="src"
        class="offer-img tw-rounded-t-xl md:tw-rounded tw-cursor-pointer"
        @click="showPopup = true"
      ></v-img>

      <div class="tw-flex tw-relative tw-flex-grow tw-overflow-hidden">
        <!-- left side -->

        <div class="tw-flex-grow left">
          <h4 class="tw-p-0 tw-pl-0 tw-text-left">
            {{ name }}
          </h4>
          <div
            class="
              tw-font-montserrat-regular
              tw-text-base
              tw-text-paragraph
              tw-leading-6
              description
            "
          >
            {{ description }}
          </div>

          <div
            class="
              tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-0
            "
          >
            <div
              class="add-to-cart-button tw-inline-flex add-btn"
              v-bind:class="type === 'restaurants' ? 'reserve-btn' : 'add-btn'"
              @click="
                type === 'activities' || type === 'restaurants'
                  ? (showModal = true)
                  : addItemToCart()
              "
            >
              <span>
                <CartIcon class="tw-inline-block tw-h-3 tw-w-3 cart-icon" />
                <span class="tw-inline-block tw-h-4 tw-leading-4">{{
                  btnText
                }}</span></span
              >
            </div>

            <div
              class="
                tw-text-right
                tw-font-poppins-semi-bold
                tw-text-title
                tw-font-semibold
                tw-flex-grow
                tw-leading-8
                tw-h-8
                price
              "
            >
              <template v-if="type !== 'restaurants'">
                <div>{{ priceWithCurrency }}</div>

                <div class="per-adult">Per Adult</div>
              </template>

              <template v-else>
                <div v-if="price < 10">
                  €<span class="tw-opacity-50">€€€</span>
                </div>
                <div v-else-if="price < 35">
                  €€<span class="tw-opacity-50">€€</span>
                </div>
                <div v-else-if="price < 85">
                  €€€<span class="tw-opacity-50">€</span>
                </div>
                <div v-else>€€€€</div>
              </template>
            </div>
          </div>
        </div>

        <transition name="fade" appear>
          <div
            class="modal-overlay"
            v-if="showModal"
            @click="showModal = false"
          ></div>
        </transition>
        <date-modal
          v-if="showModal"
          :item="item"
          @close="closeModal"
          @update="updateModal"
        ></date-modal>
      </div>
    </div>
    <IframePopup
      v-if="showPopup"
      @togglePopup="
        () => {
          showPopup = !showPopup;
        }
      "
      :src="this.url"
      @addItemToCart="
        type === 'activities' || type === 'restaurants'
          ? (showModal = true)
          : addItemToCart();
        !showPopup;
      "
    />
  </MatchMedia>
</template>

<script>
import dateModal from './DateModal.vue';
import IframePopup from './components/IframePopup.vue';
import { MatchMedia } from 'vue-component-media-queries';
import { mapActions, mapGetters } from 'vuex';
import CartIcon from '@/assets/inspirationPage/cart.svg';
import RestaurantIcon from '@/assets/inspirationPage/tabicons/restaurant_w.svg';
import ActivitiesIcon from '@/assets/inspirationPage/tabicons/activities_w.svg';
import { transformImage } from '@/utils/transformImage';

export default {
  name: 'OfferCard',
  components: {
    CartIcon,
    MatchMedia,
    dateModal,
    IframePopup,
    RestaurantIcon,
    ActivitiesIcon,
  },
  props: {
    id: String,
    name: String,
    description: String,
    image: String,
    price: Number,
    url: String,
    type: String,
    drawer: Boolean,
    item: Object,
  },
  data: function() {
    return {
      savedInBookmarks: false,
      savedInFavorites: false,
      addedToCart: false,
      showModal: false,
      showPopup: false,
    };
  },
  computed: {
    priceWithCurrency() {
      return `€${this.price}`;
    },
    src() {
      return transformImage(this.image, 200);
    },
    ...mapGetters([ 'totalCartCount', 'drawerState' ]),
    drawerState: {
      get() {
        return this.$store.getters.drawerState;
      },
      set(v) {
        this.$store.commit('toggleDrawer', v);
      },
    },
    btnText() {
      return this.type === 'restaurants' ? 'Reserve Now' : 'Add to cart';
    },
  },
  methods: {
    ...mapActions([ 'addExperienceToCart', 'updateDates' ]),
    openItemLink() {
      window.open(this.url, '_blank');
    },
    addItemToCart() {
      this.addExperienceToCart({
        id: this.id,
        name: this.name,
        image: this.image,
        details: { description: this.description },
        price: this.price,
        quantity: 1,
        type: this.type,
      });
      this.drawerState = !this.drawerState;
    },
    truncate: function(str, n) {
      return str.length > n ? str.substr(0, n) + '...' : str;
    },
    closeModal() {
      this.showModal = false;
    },
    updateModal(name, date, time = null, quantity = 1) {
      if (this.type !== 'activities') {
        quantity = 1;
      }
      this.addExperienceToCart({
        id: this.id,
        name: this.name,
        image: this.image,
        price: this.price,
        quantity: quantity,
        details: { description: this.description },
        type: this.type,
        date,
      });
      this.updateDates({ name: this.name, date, time });
      this.drawerState = !this.drawerState;
      this.showModal = false;
    },
    showInstagram() {
      window.open('https://instagram.com/' + this.influencerId);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  border: none;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06) !important;
  border-radius: 18px;

  @media (min-width: 1060px) {
    max-width: 297.66px;
    // width: 297.66px;
  }

  display: flex;
  flex-direction: column;
  height: auto;
  max-height: 530px;
  h4 {
    font-family: PoppinsSemiBold;
    font-weight: 600;
    font-size: 17.0092px;
    line-height: 23px;
    letter-spacing: -0.04em;
    color: #28262e;
  }

  .description {
    font-family: MontserratRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 11.3394px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #747378;
    margin: 8px 0;
  }

  h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
    white-space: nowrap;
  }
  
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
  }
  & > div {
    padding: 24px;
  }
}

.offer-img {
  @apply tw-w-full tw-object-cover;
  height: 175.76px;
  min-height: 175.76px;
  border-radius: 18px 18px 0px 0px;

  @media (min-width: 1060px) {
    max-width: inherit;
    width: inherit;
  }
}

.modal-overlay {
  @apply tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-bg-black tw-opacity-80;
  z-index: 98;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  max-width: 512px;
  background-color: #fff;
  border-radius: 16px;
  padding: 25px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.v-rating button {
  padding-left: 0 !important;
}

.add-to-cart-button {
  font-family: MontserratSemiBold;

  font-size: 11.3394px;
  line-height: 11px;
  letter-spacing: -0.02em;
  color: #d62a53;
}

.cart-icon {
  margin-right: 8.51px;
}
.price {
  font-size: 17.0092px;
  line-height: 23px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #28262e;
  margin-left: auto;
}

.left {
  max-width: calc(100%);
  width: calc(100%);
  min-width: calc(100%);
}

.per-adult {
  font-family: MontserratRegular;
  font-size: 11.4839px;
  line-height: 11px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #747378;
  font-weight: medium;
}

.icon {
  position: absolute;
  width: 32px;
  height: 32px;
  background: #d62a53;
  border-radius: 130px;
  left: -7px;
  display: flex;
  top: -7px;
      z-index: 1;

  padding: 0 !important;

  div {
    width: 32px;
    height: 32px;
  }

  svg {
    color: white;
    margin: auto;
    width: 20px;
    height: 20px;
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
  }
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.add-to-cart-button {
  width: 135px;
  height: 28px;
  left: 845px;
  top: 914px;
  background: #d62a53;
  border-radius: 90px;
  display: flex;
  transition: 0.3s;
  cursor: pointer;
  font-family: MontserratSemiBold;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;

  svg {
    height: 14px;
    width: 14px;
  }

  & > * {
    margin: auto;
  }
}

.reserve-btn {
  width: 145px;
}
.add-to-cart-button:hover {
  opacity: 0.9;
}
.add-to-cart-button:active {
  opacity: 0.75;
}
</style>
