<template>
  <button @click="handleTap">
    <div :class="[isPink ? 'tap-to-view-pink' : 'tap-to-view-gray']">
      <div class="tap tw-px-2 tw-rounded-full" :class="[isPink ? 'pink-theme' : 'gray-theme']">
        Tap to view
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'TapToViewButton',
  props:{
    handleTap: Function,
    isPink: Boolean,
  }

};
</script>

<style lang="scss" scoped>

.tap-to-view-gray {
  @apply tw-relative tw-inline-block tw-mb-6;

  &:after {
    content: "";
    position: absolute;
    bottom: -0.625rem;
    margin-top: -0.625rem;
    margin-left : -0.625rem;
    border-left: 0.625rem solid transparent;
    border-right: 0.625rem solid transparent;
    border-top: 0.625rem solid rgba(243, 244, 246,0.4);
}

}

.tap-to-view-pink {
  @apply tw-relative tw-inline-block tw-mb-6;

  &:after {
    content: "";
    position: absolute;
    bottom: -0.625rem;
    margin-top: -0.625rem;
    margin-left : -0.625rem;
    border-left: 0.625rem solid transparent;
    border-right: 0.625rem solid transparent;
    border-top: 0.625rem solid rgba(214, 42, 83, 0.4);
}

}

.pink-theme {
  @apply tw-text-sm tw-text-primary tw-bg-primary tw-bg-opacity-40 tw-px-3 tw-py-1;
}

.gray-theme {
  @apply tw-text-sm tw-text-white tw-bg-gray-100 tw-bg-opacity-40 tw-px-3 tw-py-1;
}

</style>
