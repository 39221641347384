<template>
  <div class="footer-container">
    <!-- <template v-if="!loading"> -->
      <div class="tw-flex tw-pr-4 tw-mb-8">
        <div class="tw-mx-0 price tw-flex-1 tw-mt-3">
          €{{ roundToTwo(this.hotel.net / totalNights) }} /
          <span class="light"> Per Night </span>
          <div class="tw-mt-2 dates">Property fee for this stay</div>
        </div>
        <div @click="HandleToSteps">
          <v-btn class="reservebutton price"> Select a Room </v-btn>
        </div>
      </div>
    <!-- </template>
    <div class="loading" v-else><div>loading...</div></div> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'HotelStickyFooter',
  props: [ 'hotel' ],
  data() {
    return {
      totalNights: ''
    };
  },
  methods: {
    ...mapActions([ 'addPackageCityExperienceId' ]),
    HandleToSteps() {
      //this.addPackageCityExperienceId({ id: this.experience.city_id });
      this.$router.push({ name: 'MobileFlights' });
    },
    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2');
    },
  },
  async mounted() {
    const splitRateKey = this.hotel.rateKey.split('|');
    var checkInDate = moment(splitRateKey[0], 'YYYYMMDD').format(
      'DD MMM YYYY'
    );
    var checkOutDate = moment(splitRateKey[1], 'YYYYMMDD').format(
      'DD MMM YYYY'
    );
    this.totalNights = Math.abs(
      moment(checkInDate, 'DD MMM YYYY')
        .startOf('day')
        .diff(moment(checkOutDate, 'DD MMM YYYY').startOf('day'), 'days')
    );
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';
.footer-container {
  background-color: white;
  color: #111216;
  height: 100px;
  .price {
    font-family: 'SFProTextSemibold';
    font-size: 1.25rem;
  }
  .dates {
    font-family: 'SFProTextMedium';
    letter-spacing: -0.03rem;
    text-decoration: underline;
    font-size: 1rem;
  }
  .light {
    font-family: 'SFProTextRegular';
    font-size: 0.95rem;
  }
  .reservebutton {
    background-color: $primary;
    color: white;
    letter-spacing: -0.03rem;
    font-size: 1rem;
  }
  .v-btn {
    width: 9rem !important;
    height: 3rem !important;
  }
}

.loading {
  margin: auto;
  height: 100px;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
    margin: auto;
    margin-top: 35px;
  }
}
</style>
