<template>
  <div class="tw-font-sfprodisplay-semibold tw-text-title">
    <div class="header">Rooms</div>
    <div v-for="(room, index) in hotel.rooms" :key="index">
      <div class="hotel-top-images tw-mr-6">
        <!-- {{ FilterRoomImages('DBL.DX-VM') }} -->
     
      <!-- <img
        v-if="this.roomImages > 0"
        style="height: 50vw; width: 100%"
        :src="this.roomImages[0].path"
      />
      <div class="second-row">
        <img v-if="this.roomImages > 1" :src="this.roomImages[1].path" />
        <img v-if="this.roomImages > 2" :src="this.roomImages[2].path" />
        <img v-if="this.roomImages > 3" :src="this.roomImages[3].path" /> -->
        <img style="height: 50vw; width: 100%" :src="hotel.images[0].path" />
      <div class="second-row">
        <ImageQualityContainer :src="hotel.images[3].path" />
        <ImageQualityContainer :src="hotel.images[4].path" />
        <ImageQualityContainer :src="hotel.images[5].path" />
      
      <!-- {{EmptyImageArray()}} -->
       </div>
      </div>
      <div class="name">
        {{ room.name }}
      </div>
      <div class="tw-flex tw-flex-row">
        <BedIcon class="tw-w-4 tw-h-4 tw-mr-2 tw-mb-6" />
        <div class="bed">
          {{ splitName(room.name) }} {{ checkBeds(room.name) }}
        </div>
      </div>
      <div v-for="(rate, rateIndex) in room.rates" :key="rateIndex">
        <div
          class="
            card
            tw-border tw-shadow tw-rounded-lg tw-mr-5 tw-mb-4 tw-relative
          "
        >
          <div v-if="rate.rateClass === 'NRF'" class="cancelPolicy">
            NON REFUNDABLE
          </div>
          <div v-else class="cancelPolicy">REFUNDABLE</div>
          <div v-if="rate.boardCode === 'BB'">
            <div class="tw-flex tw-flex-row">
              <WifiIcon class="tw-w-6 tw-h-6 tw-ml-4" />
              <div class="bb">Free Wifi</div>
            </div>
            <div class="tw-flex tw-flex-row">
              <BreakfastIcon class="tw-w-6 tw-h-6 tw-ml-4" />
              <div class="tw-flex tw-flex-col bb">
                <div>Breakfast buffet</div>
                <div>included</div>
              </div>
            </div>
            <v-btn
              class="add-room-btn tw-mx-6"
              color="primary"
              @click="
                () => {
                  handleAddToCart(hotel);
                }
              "
              >Reserve</v-btn
            >
          </div>
          <div v-else>
            <div class="tw-flex tw-flex-row">
              <WifiIcon class="tw-w-6 tw-h-6 tw-ml-4" />
              <div class="bb">Free Wifi</div>
            </div>
            <v-btn
              class="add-room-btn tw-mt-6 tw-mx-6"
              color="primary"
              @click="
                () => {
                  handleAddToCart(hotel);
                }
              "
              >Reserve</v-btn
            >
          </div>
          <div class="price-container tw-flex tw-flex-col">
            <div class="tw-flex tw-flex-row tw-mb-2">
              <div class="price">€{{ roundToTwo(rate.net / totalNights) }}</div>
              <div class="pernight">/ Per Night</div>
            </div>
            <div class="tax-title">Tax and fees included</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BedIcon from '@/assets/mobile/hotels.svg';
import BreakfastIcon from '@/assets/mobile/packageview/breakfast.svg';
import WifiIcon from '@/assets/mobile/packageview/wifi.svg';
import moment from 'moment';
import ImageQualityContainer from '@/views/inspiration/tab-items/components/ImageQualityContainer.vue';
export default {
  name: 'MobileRooms',
  data() {
    return {
      checkInDate: '',
      checkOutDate: '',
      totalNights: '',
      noOfBeds: '',
      roomImages: [],
    };
  },
  props: {
    hotel: Object,
  },
  components: {
    BedIcon,
    BreakfastIcon,
    WifiIcon,
    ImageQualityContainer,
  },
  computed: {},
  methods: {
    roundToTwo(num) {
      return +(Math.round(num + 'e+2') + 'e-2');
    },
    splitName(value) {
      var match = value.match(/\d.*/);
      var result = match ? match[0] : '1';
      return result;
    },
    checkBeds(value) {
      this.noOfBeds = value.replace(/[^0-9]/g, '');
      var bed = '';
      if (this.noOfBeds === '') {
        bed = 'bed';
      } else if (this.noOfBeds > 0) {
        bed = 'bed';
      } else if (this.noOfBeds > 1) {
        bed = 'beds';
      } else {
        bed = '';
      }
      return bed;
    },
    FilterRoomImages(current) {
      for (var i in this.hotel.images) {
        if (current === i.roomCode) {
          this.roomImages.push(i);
        }
      }
      console.log(i);
    },
    EmptyImageArray() {
      this.roomImages = [];
    }
  },
  mounted() {
    const splitRateKey = this.hotel.rateKey.split('|');
    this.checkInDate = moment(splitRateKey[0], 'YYYYMMDD').format(
      'DD MMM YYYY'
    );
    this.checkOutDate = moment(splitRateKey[1], 'YYYYMMDD').format(
      'DD MMM YYYY'
    );
    this.totalNights = Math.abs(
      moment(this.checkInDate, 'DD MMM YYYY')
        .startOf('day')
        .diff(moment(this.checkOutDate, 'DD MMM YYYY').startOf('day'), 'days')
    );
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
.header {
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: -0.03rem;
  margin-bottom: 1rem;
}

.name {
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: -0.03rem;
  margin-bottom: 0.75rem;
  margin-top: 1rem;
}

.card {
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.01) !important;
}

.cancelPolicy {
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-left: 0.75rem;
  color: $paragraph;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.bb {
  font-family: "SFProTextRegular";
  font-size: 0.85rem;
  line-height: 1.15rem;
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  letter-spacing: -0.03rem;
  color: $title;
}

.price-container {
  top: 0;
  right: 0;
  position: absolute;
  margin-top: 2.75rem;
  .price {
    font-family: "SFProDisplaySemibold";
    font-size: 1.1rem;
    line-height: 1rem;
    color: $title;
  }

  .pernight {
    font-family: "SFProDisplayMedium";
    font-size: 0.75rem;
    line-height: 1rem;
    color: #7d7d84;
    margin-left: 0.125rem;
    margin-right: 1rem;
  }
}

.tax-title {
  font-family: "SFProTextRegular";
  font-size: 0.75rem;
  line-height: 1rem;
  color: $paragraph;
  margin-bottom: 3.25rem;
}

.add-room-btn {
  font-family: "SFProDisplaySemibold";
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: -0.03rem;
  margin-bottom: 1rem;
}

.v-btn {
  height: 34px !important;
  width: 90% !important;
}

.bed {
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-top: 0.15rem;
  color: $paragraph;
}

.second-row {
  max-height: 20vh;
  height: 20vh;
}

.hotel-top-images {
  > div:nth-child(2) {
    //height: 548px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 100%;
    align-content: space-around;
    justify-content: space-between;
    //padding: 0 10px;
  }
}
</style>
