<template>
  <div class="tw-mt-5 tw-overflow-hidden tw-shadow-md tw-rounded-3xl tw-mr-3 md:tw-mt-0 topcardlayout">
 
    <div
      :style="{
        'background-image': `url(${item.image})`,
      }"
      class="image tw-h-56 tw-w-full tw-bg-cover tw-shadow-xl"
    />
    <div class="tw-p-3">
      <div class="tw-flex tw-justify-between tw-items-center tw-mb-3">
      <div class="title">{{item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name}}</div>
      <div class="price">€ {{item.price}}</div>
      </div>
          
      <div class="tw-flex tw-items-end">
      <div class="description">{{item.description.substring(0, 65) + '...'}}</div>
      <div class="tw-flex tw-text-primary reverse tw-items-center"
                ><v-icon color="primary">mdi-cart-outline </v-icon><span class="tw-flex">Reserve Now</span></div
              >
              </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [ 'item' ]
};
</script>

<style scoped>
.root {
  height: 400px;
  width: 350px;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(40px);
  border-radius: 20px;
  overflow: hidden;
  margin-right: 10px;
}

.topcardlayout {
  @apply tw-w-1/3;
  @include respond-to(lg) {
    @apply tw-w-2/3 !important;
  }
}
/* .image {
 width: 420px;
height: 248px;
} */
.title {
  font-family: 'PoppinsMedium';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
letter-spacing: -0.04em;
}
.description {
  font-family: 'MontserratRegular';
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
letter-spacing: -0.02em;
}
.reverse {
  font-family: 'MontserratRegular';
font-style: normal;
font-weight: 600;
font-size: 12px;
letter-spacing: -0.02em;
width: 240px;
}
.price {
  font-family: 'PoppinsMedium';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
}
</style>
