<template>
    <div class="header">
        About this Property
        <div class="facilities tw-mt-6">
        <div class="tw-mb-4"> 
            Property Location
        </div>
        <div class="tw-mb-4"> 
            Rooms
        </div>
        <div class="tw-mb-4"> 
            Amenities
        </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'InfoComponent',
  data() {
    return {

    };
  }
};
</script>

<style lang='scss' scoped>
@import "~@/assets/styles/variables.scss";
.header {
    font-family: 'SFProDisplaySemibold';
    font-size: 1rem;
    line-height: 1.15rem;
    letter-spacing: -0.03rem;
    color: $title;
    margin-left: 1rem;
}
.facilities {
    font-family: 'SFProDisplaySemibold';
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: -0.03rem;
    color: $title;
}
</style>
