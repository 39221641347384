<template>
  <mobile-modal :showing="true" class="body">
    <hotel-popup-navbar :hotel="hotel" @close="close" class="d-sm-none"></hotel-popup-navbar>
    <FlowBreadcrumbs class="tw-mb-6 tw-mt-4"/>
    <div class="hotel-top-images">
      <div class="first-row-height">
        <ImageQualityContainer :src="hotel.images[1].path" />
        <ImageQualityContainer :src="hotel.images[0].path" />
      </div>
      <div class="second-row">
        <ImageQualityContainer :src="hotel.images[3].path" />
        <ImageQualityContainer :src="hotel.images[4].path" />
        <span class="more-photos">
          <span @click="tab = 2" class="text"
            >+{{ hotel.images.length - 8 }} Photos</span
          >
          <ImageQualityContainer class="top-img" :src="hotel.images[7].path" />
        </span>
      </div>
    </div>
    <v-card class="hotel-details">
      <div class="tw-px-2">
        <div class="tw-mt-6">
          <v-card-title>
            <div class="tw-grid tw-grid-rows tw-w-full">
              <div class="row-start-1 row-end-1">
                <div class="tw-flex tw-flex-row tw-grid-cols-2">
                  <div class="tw-flex tw-flex-col tw-w-4/5">
                    <!-- <v-rating
                          :value="hotel.categoryName"
                          color="#F1BF42"
                          dense
                          half-increments
                          readonly
                          background-color="#DADCE0"
                          size="20"
                          empty-icon="$ratingFull"
                        ></v-rating> -->
                    <div class="tw-flex tw-flex-row star-review tw-mb-2">
                      {{ hotel.reviews[0].rate.toFixed(1) }}
                      <StarIcon class="tw-w-3 tw-h-3 tw-mx-1 tw-mt-0.5" />
                    </div>
                    <div class="tw-p-0 tw-text-left hotel-name">
                      {{ hotel.name }}
                    </div>
                    <div class="tw-flex">
                      <div class="tw-mt-4">
                        <review-rating :hotel="hotel" />
                      </div>
                    </div>
                    <div class="chips">
                      <span
                        v-for="surrounding in surroundings"
                        :key="surrounding.number"
                      >
                        <div
                          v-if="surrounding.facilityCode === 40"
                          class="chip-grey"
                        >
                          <div class="tw-flex">
                            <BeachPalm />
                          </div>
                          <template
                            v-if="surrounding.distance >= 1000"
                            class="tw-ml-auto"
                          >
                            {{ surrounding.distance / 1000 }}km
                          </template>
                          <template v-else class="tw-ml-auto">
                            {{ surrounding.distance }}m
                          </template>
                          from {{ surrounding.description }}
                        </div></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-title>
          <hr class="tw-mt-4 tw--ml-12 tw-w-auto" />
          <v-card-title>
            <div class="amenities tw-flex tw-flex-col">
              <h3>Popular Amenities</h3>
              <amenities-icon
                v-for="item in amenities"
                :key="item.description"
                :description="item.description"
              >
                <v-icon color="primary">
                  {{ item.icon }}
                </v-icon>
              </amenities-icon>
            </div>
          </v-card-title>
          <hr class="tw-mt-4 tw--ml-12 tw-w-auto" />
        </div>
        <div class="tw-my-3 tw-ml-3">
          <GmapMap
            :center="{
              lat: parseFloat(hotel.latitude),
              lng: parseFloat(hotel.longitude),
            }"
            :zoom="19"
            style="width: 89vw; height: 190px"
          >
            <GmapMarker
              :position="{
                lat: parseFloat(hotel.latitude),
                lng: parseFloat(hotel.longitude),
              }"
          /></GmapMap>
        </div>
        <div class="tw-flex tw-flex-row tw-mt-1 tw-ml-3">
          <div class="location-pin tw-rounded-l-full">
            <Map-pin class="tw-w-3 tw-h-3 tw-mr-2" />
          </div>
          <p class="address tw-rounded-r-full">
            {{hotel.address.content}}
          </p>
        </div>
      </div>
      <div class="setwidth tw-ml-5">
        <hotel-description
          :description="hotel.description.content"
        ></hotel-description>
      </div>
      <hr class="tw-mt-4 tw--ml-12 tw-w-auto" />
      <div class="tw-ml-5">
        <RoomComponent :hotel="hotel"/>
      </div>
      <hr class="tw-mt-4 tw--ml-12 tw-w-auto" />
      <div>
        <InfoComponent :hotel="hotel" />
      </div>
    </v-card>
    <HotelFooter :hotel="hotel" class="tw-bottom-0 sticky tw-w-full tw-h-12"/>
  </mobile-modal>
</template>

<script>
import StarIcon from '@/assets/mobile/star.svg';
import ReviewRating from '@/views/inspiration/tab-items/components/ReviewRating.vue';
import HotelDescription from '@/views/inspiration/tab-items/components/HotelDescription.vue';
import AmenitiesIcon from '@/views/inspiration/tab-items/components/AmenitiesIcon.vue';
// import ModifySearch from './ModifySearch.vue';
import ImageQualityContainer from '@/views/inspiration/tab-items/components/ImageQualityContainer.vue';
import topAmenities from '@/assets/json/topAmenities.json';
import BeachPalm from '@/assets/hotelDetails/beach-palm.svg';
import MapPin from '@/assets/mobile/blackmappin.svg';
import RoomComponent from './Rooms.vue';
import InfoComponent from './Info.vue';
import HotelFooter from './HotelInfoFooter.vue';
import MobileModal from '@/components/MobileModal.vue';
import FlowBreadcrumbs from '../../FlowBreadcrumbs.vue';
import HotelPopupNavbar from './HotelPopupNav.vue';
export default {
  name: 'MobileHotelDescription',
  props: {
    hotel: Object,
  },
  emits: [ 'addToCard' ],
  components: {
    StarIcon,
    ImageQualityContainer,
    ReviewRating,
    HotelDescription,
    AmenitiesIcon,
    BeachPalm,
    MapPin,
    RoomComponent,
    InfoComponent,
    HotelFooter,
    MobileModal,
    FlowBreadcrumbs,
    HotelPopupNavbar
  },
  computed: {
    surroundings: {
      get() {
        return this.hotel.facilities.filter(obj => {
          return obj.facilityGroupCode === 40;
        });
      },
    },
    amenities: function() {
      return this.hotel.facilities
        .filter(it => {
          return topAmenities.some(
            val =>
              val.facilityGroupName === it.facilityGroupName &&
              val.description === it.description
          );
        })
        .map(item => {
          const data = topAmenities.find(
            val =>
              val.facilityGroupName === item.facilityGroupName &&
              val.description === item.description
          );
          return { ...item, icon: data.icon };
        })
        .slice(0, 4);
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleAddToCart(_) {
      this.$emit('addToCard');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";
.body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.star-review {
  font-family: "SFProTextMedium";
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: -0.03rem;
  color: $paragraph;
}

.hotel-name {
  font-family: "SFProTextSemibold";
  font-size: 1.25rem;
  line-height: 1.25rem;
  letter-spacing: -0.03rem;
  color: $title;
}
.chips {
  text-align: left;

  svg {
    transform: scale(0.75);
    margin: auto;
    margin-right: 8.73px;
  }
}
.chip-grey {
  margin-top: 24px;

  background: #f1f1f2;
  font-family: "SFProTextMedium";
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #747378;
  height: 28px;
  line-height: 12px;
  display: inline-flex;
  border-radius: 16px;
  padding: 0 16px;
  margin-right: 12px;
}

.address {
  font-family: 'SFProTextMedium';
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: $title;
}

.v-icon {
  font-size: 14px !important;
}

.v-tab {
  min-width: 25% !important;
  font-family: "MontserratSemiBold";
  font-size: 1rem;
  letter-spacing: -0.03rem;
  text-transform: unset;
}

.app-modal {
  background: rgba(0, 0, 0, 0.8);
}

.label {
  font-family: "PoppinsSemiBold";
  color: #28262e;
}
.v-rating {
  text-align: left;
}
.image-height {
  height: 600px;
  object-fit: cover;

  @media (min-width: 1090px) {
    max-height: 33.3vh;
  }
}

.sticky {
  padding: 1em;
  position: sticky;
}

.add-room-btn {
  height: 38px !important;
  background: #d62a53 !important;
  border-radius: 90px !important;
  box-shadow: none !important;
}

.setwidth {
  width: 100%;
}

.first-row-height {
  max-height: 25vh;
}

.second-row {
  max-height: 20vh;
  height: 20vh;
}

.hotel-top-images {
  > div:nth-child(1) {
    display: grid;
    grid-template-columns: 33.33% 66.66%;
    grid-template-rows: 100%;
    align-content: space-around;
    justify-content: space-between;
    overflow: hidden;
  }
  > div:nth-child(2) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: 100%;
    align-content: space-around;
    justify-content: space-between;
  }
}


.more-photos {
  position: relative;
  overflow: hidden;

  .text {
    position: absolute;
    color: white;
    z-index: 999;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    border-radius: 4 px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }

  .top-img {
    height: 100%;
  }
}

.amenities {
  h3 {
    font-family: "SFProDisplaySemibold";
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: -0.03rem;
    color: $title;
  }
}

hr {
  border-color: #747378;
  margin-bottom: 1.5rem;
  opacity: 0.1;
}

.modify-search {
  padding-left: 0;
  padding-right: 0;
}

.room-name {
  font-family: MontserratSemiBold;
  font-size: 0.59vw;
  letter-spacing: -0.02em;
  color: #28262e;
  text-align: right;
  height: 20px;
}
.cancellation {
  font-family: MontserratMedium;
  font-size: 12px;
  letter-spacing: -0.02em;

  color: hsl(252, 2%, 46%);
}
</style>
