export const dateForCalendar = (date = new Date()) => {
  const month = date.getMonth() + 1;
  const dayOfMonth = date.getDate();
  return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${
    dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth
  }`;
};

export const dateForAvailabilityCalendar = (date = new Date()) => {
  const month = date.getMonth() + 1;
  const dayOfMonth = date.getDate();
  return `${dayOfMonth < 10 ? '0' + dayOfMonth : dayOfMonth}/${
    month < 10 ? '0' + month : month
  }/${date
    .getFullYear()
    .toString()
    .substr(-2)}`;
};

export const diffDays = (dateTo = new Date(), dateFrom = new Date()) =>
  (new Date(dateTo).getTime() - new Date(dateFrom).getTime()) /
  (1000 * 3600 * 24);

export const convertToDatePickerFormat = date => {
  const time = date.split('-');
  const convertDate = new Date(`${time[0]}-${time[1]}-${time[2]}`);
  return dateForCalendar(convertDate);
};

export const convertDate = inputFormat => {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat);
  return [ pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear() ].join('/');
};

export const dateFromSec = seconds => new Date(seconds ? seconds * 1000 : Date.now());
export const secondsFromDate = date => Math.floor(date.getTime() / 1000);
