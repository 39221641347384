<template>
  <div
    class="story-container tw-rounded-2xl tw-relative tw-overflow-hidden"
    :class="{'is-active': currentExperienceId === experience.exp_id}"
    @click="$emit('click')"
  >
  <div class="tw-absolute tw-h-full tw-w-full overlay">
    <div 
      :style="{
            backgroundImage: 'url(' + experience.background_image + ')',
            backgroundColor: 'rgba(0,0,0,0.75)',
          }"
      class="tw-bg-center tw-bg-cover tw-h-full tw-w-full"
    />
  </div>
    
    <div class="tw-absolute">
      <div class="tw-flex tw-flex-row tw-items-center tw-mt-2 tw-ml-2">
        <div
          :style="{
            backgroundImage: 'url(' + experience.influencer_data.image + ')',
          }"
        class="tw-bg-center tw-bg-cover tw-border-2 tw-border-white tw-rounded-full tw-h-8 tw-w-8 tw-mb-4 tw-mr-2"
        />
        <div>
          <div class="tw-text-white tw-text-xl tw-mb-0 tw--my-3"> {{ experience.influencer_data.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DesktopGalleryCard',
  props: {
    experience: Object,
    currentExperienceId: String,
  },
};
</script>

<style lang="scss" scoped>

.story-container {
  @apply tw-pb-56 tw-relative tw-border-4 tw-border-transparent tw-bg-origin-border tw-box-border tw-h-80 tw-w-64;
   cursor: pointer;

  &.is-active {
    @apply tw-border-4 tw-border-white;
  }

  
  box-shadow: 0px 4px 4px 0px #00000059;
  box-shadow: inset 0 0 100rem rgba(50, 50, 50, 0.7);
  text-shadow: 0px 0px 5px #000;
  transform: scale(0.95);
}

.overlay:before {
  content:"";
  @apply tw-bg-black tw-bg-opacity-30 tw-absolute tw-h-full tw-w-full;
}


</style>
