<template>
  <button class="tw-max-w-26 tw-max-h-12 tw-bg-primary tw-rounded tw-text-white tw-py-2 tw-px-10 tw-flex tw-flex-row tw-items-center tw-shadow tw-gap-2" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'PrimaryButton',

};
</script>

<style>

</style>
