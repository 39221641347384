<template>
  <div class="nav-container">
    <div class="tw-flex tw-flex-row tw-grid tw-grid-cols-6 tw-gap-1 tw-mt-4">
      <div class="tw-pl-8 tw-col-span-2">
        <v-icon class="steps-chevron" @click="close">mdi-chevron-left </v-icon>
      </div>
      <div class="tw-z-40 tw-mt-1 tw-col-span-3">
        <div class="hotelname">
          {{this.hotel.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotelPopupNav',
  data: function() {
    return {
      cityData: null,
    };
  },
  props: {
    hotel: Object
  },
  methods: {
    close() {
      this.$emit('close');
    }
  },
};
</script>

<style lang="scss" scoped>
.nav-container {
  height: 50px;
  box-shadow: 0px 4px 6px rgba(224, 224, 224, 0.04), inset 0px -1px 0px #f0f0f0;
  .hotelname {
    font-family: "SFProDisplaySemibold";
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.03rem;
    margin-left: 1rem !important;
  }
}
.v-icon.steps-chevron {
  color: black !important;
}
</style>
