<template>
  <div class="outer" v-bind:class="{ enabled: enabled }">
    <div
      class="overlay"
      @click="
        () => {
          if (isExpressCheckout) {
            exitView();
          } else {
            $emit('toggle-expand');
          }
        }
      "
    ></div>

    <template v-if="enabled">
      <div
        @click="
          () => {
            $emit('prev-story-slide');
          }
        "
        class="button prev"
      >
        <PrevIcon />
      </div>
    </template>
    <div class="inner"><slot></slot></div>

    <template v-if="enabled">
      <div
        @click="
          () => {
            $emit('next-story-slide');
          }
        "
        class="button next"
      >
        <NextIcon />
      </div>
    </template>
    <div
      class="button close"
      v-if="enabled && !isExpressCheckout"
      @click="
        () => {
          $emit('toggle-expand');
        }
      "
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <div v-if="isExpressCheckout" class="button close" @click="exitView">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 6L6 18"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import NextIcon from '@/assets/inspirationPage/chevron-right.svg';
import PrevIcon from '@/assets/inspirationPage/chevron-left.svg';

export default {
  name: 'Story',
  props: {
    enabled: Boolean,
  },
  components: {
    NextIcon,
    PrevIcon,
  },
  computed: {
    isExpressCheckout() {
      return this.$route.name === 'ExpressStoryView';
    },
    isInspirationPage() {
      return this.$route.name === 'InspirationPage';
    },
    isLandingPage() {
      return this.$route.name === 'LandingPage';
    },
  },
  methods: {
    exitView() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(5px);
  transition: 0.4s;
  transition-property: background-color, backdrop-filter;
}

.inner {
  transition: 0.4s;
  // transition-property: background-color, backdrop-filter;
}
.enabled {
  display: flex;
  .inner {
    max-width: 28.125vw;
    margin: auto;
    z-index: 9999;
    display: block;
    width: 100%;
  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9997;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
  }
}
.close {
  right: calc(50% - (28.125vw / 2) - 72px);
  top: 1vw;
}
.prev {
  left: 7.3vw;
  top: calc(50vh - 24px);
}
.next {
  right: 7.3vw;
  top: calc(50vh - 24px);
}

.button {
  position: fixed;
  z-index: 99999999;

  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  width: 40px;
  height: 40px;
  backdrop-filter: blur(30px);
  transition: 0.3s;
}

.button:hover {
  background: rgba(255, 255, 255, 0.25);
}
.button:active {
  background: rgba(255, 255, 255, 0.3);
}
</style>
