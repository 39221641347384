<template>
  <div v-if="experience" class="main-content-container hidden-xs-only">
    <Breadcrumbs
      :city="this.city"
      :experience="experience"
      class="tw-mt-6 tw-ml-16"
    >
    </Breadcrumbs>
    <div
      class="
        header-section
        tw-flex tw-flex-col tw-my-0 tw-mx-6
        md:tw-mx-24
        tw-max-w-screen
      "
    >
      <div class="top-container tw-flex">
        <div class="tw-flex tw-flex-col storycontainer">
          <ExpandStoryCarousel
            @toggle-expand="
              () => {
                expandStoryCarouselEnabled = !expandStoryCarouselEnabled;
              }
            "
            @next-story-slide="
              () => {
                story().next();
              }
            "
            @prev-story-slide="
              () => {
                story().prev();
              }
            "
            :enabled="expandStoryCarouselEnabled"
          >
            <div class="">
              <Story
                :showButtons="!expandStoryCarouselEnabled"
                :items="storyItems"
                :storyId="experience.exp_id"
                ref="story"
                class="story"
                :active="true"
                :influencer="experience.influencer_data"
                :expandStoryCarouselEnabled="expandStoryCarouselEnabled"
                @toggle-expand="
                  () => {
                    expandStoryCarouselEnabled = !expandStoryCarouselEnabled;
                  }
                "
              />
            </div>
          </ExpandStoryCarousel>
        </div>
        <div class="experience">
          <div class="tw-flex tw-flex-row">
            <div class="tw-flex tw-flex-col tw-mr-5" @click="showInstagram">
              <img
                class="influencer-image tw-cursor-pointer tw-mt-8"
                :src="experience.influencer_data.image"
              />
              <div>
                <div
                  class="influencer-name tw-cursor-pointer tw-mx-auto tw-w-32"
                >
                  {{ experience.influencer_data.name }}
                </div>
                <div class="influencer-influencer-id tw-cursor-pointer">
                  @{{ experience.influencer_data.influencer_id }}
                </div>
              </div>
            </div>
            <div class="">
              <div
                class="tw-flex tw-flex-row tw-rounded-full tw-cursor-pointer"
                @click="handleCityPage()"
              >
                <div class="location-pin tw-rounded-l-full">
                  <Map-pin class="tw-w-4 tw-h-4" />
                </div>
                <p class="location-padding tw-rounded-r-full">
                  {{ this.city.name }} , {{ this.city.country }}
                </p>
              </div>
              <div>
                <h1 class="name">
                  {{ experience.name }}
                </h1>

                <div class="description">
                  <template v-if="!expandDescription">
                    {{ experience.description.substring(0, 200) }}...
                    <span
                      class="see-more"
                      @click="
                        () => {
                          expandDescription = !expandDescription;
                        }
                      "
                      >See More</span
                    >
                  </template>
                  <template v-else>
                    {{ experience.description }}
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-row">
            <div class="influencer-pick tw-w-11/12">
              {{ experience.influencer_data.name + '’s Pick' }}
            </div>
            <div class="chevron-buttons tw-flex tw-select-none">
              <div
                @click="
                  () => {
                    if (displayExperiencesIndex > 0)
                      displayExperiencesIndex -= 1;
                  }
                "
                class="
                  tw-ml-auto
                  tw-w-8
                  tw-h-8
                  tw-mr-2
                  tw-cursor-pointer
                  tw-w-8
                  tw-h-8
                  tw-mr-4
                  tw-cursor-pointer
                  tw-bg-grey_light
                  tw-rounded-full tw-flex tw-items-center tw-justify-center
                "
                v-bind:class="
                  !(displayExperiencesIndex > 0)
                    ? 'tw-opacity-40'
                    : 'hover:tw-opacity-90'
                "
              >
                <v-icon>mdi-chevron-left </v-icon>
              </div>
              <div
                @click="
                  () => {
                    if (
                      displayExperiencesIndex <
                      experience.activities.filter(
                        value => value.description !== ''
                      ).length +
                        experience.restaurants.filter(
                          value => value.description !== ''
                        ).length -
                        3
                    )
                      displayExperiencesIndex += 1;
                  }
                "
                class="
                  tw-cursor-pointer tw-w-8 tw-h-8 tw-mr-2 tw-cursor-pointer
                  tw-bg-grey_light
                  tw-rounded-full tw-flex tw-items-center tw-justify-center
                "
                v-bind:class="
                  !(
                    displayExperiencesIndex <
                    experience.activities.filter(
                      value => value.description !== ''
                    ).length +
                      experience.restaurants.filter(
                        value => value.description !== ''
                      ).length -
                      3
                  )
                    ? 'tw-opacity-40'
                    : 'hover:tw-opacity-90'
                "
              >
                <v-icon>mdi-chevron-right </v-icon>
              </div>
            </div>
          </div>

          <div class="cards-list-container tw-flex">
            <div
              class="card-container"
              v-for="(item, i) in displayExperiences"
              :key="item.description"
            >
              <hotel-card-small
                v-if="item.hasOwnProperty('hotel')"
                :hotel="pickedHotel"
                :loadPickedHotel="loadPickedHotel"
              />
              <offer-card-small
                v-else
                :id="experience.exp_id + '/' + item.type + '/' + i"
                :name="item.name"
                :description="item.description"
                :image="item.image"
                :price="item.price"
                :url="item.url"
                :type="item.type"
                :item="item"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="tw-mx-auto tw-mt-16">
        <h1 class="ownTrip tw-font-poppins-semi-bold tw-mb-8">
          {{ 'Create your own trip to ' + this.city.name }}
        </h1>
      </div>
      <div class="description tw-mx-auto">
        Start building and booking your own trip by adding more trip components
        to your cart
      </div>

      <div
        class="
          tw-flex tw-flex-col
          content
          tw-text-center
          buyseparately
          tw-text-gray-700
        "
      >
        <div class="tw-flex tw-flex-col tw-items-center tw-w-full">
          <ul class="tab-list">
            <li
              class="tw-relative link tw-text-sm tw-text-center"
              v-for="(tab, index) in availableTabs"
              :class="{ active: index === tabIndexMap.get(activeTab) }"
              :key="tab.id"
            >
              <button
                @click="activeTab = tab.id"
                class="
                  tw-flex
                  icon-container
                  tw-rounded-full
                  tw-bg-white
                  tw-h-12
                  tw-my-1
                  tw-items-center
                  tw-justify-center
                "
              >
                <div class="tw-pl-2">
                  <component
                    v-if="index === tabIndexMap.get(activeTab)"
                    :is="tab.iconWhite"
                  />
                  <component v-else :is="tab.icon" />
                </div>
                <div class="label tw-font-poppins-medium tw-pr-2">
                  {{ tab.label }}
                </div>
              </button>
            </li>
          </ul>
        </div>

        <div
          class="
            tw-flex tw-flex-col tw-w-full tw-mb-8 tw-mt-6
            md:tw-mb-0 md:tw-mt-0
          "
        >
          <offers-container
            ref="offers"
            :active-tab="activeTab"
            :experience="experience"
            :city="city"
            :set-active-tab="
              str => {
                activeTab = str;
              }
            "
            :influencerId="experience.influencer_data.influencer_id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstagramIcon from '@/assets/inspirationPage/icon.svg';
import CartIcon from '@/assets/inspirationPage/cart.svg';
import FlightIcon from '@/assets/inspirationPage/tabicons/plane.svg';
import AccommodationIcon from '@/assets/inspirationPage/tabicons/accommodation.svg';
import ActivitiesIcon from '@/assets/inspirationPage/tabicons/activities.svg';
import NightlifeIcon from '@/assets/inspirationPage/tabicons/nightlife.svg';
import RestaurantIcon from '@/assets/inspirationPage/tabicons/restaurant.svg';
import FlightIconWhite from '@/assets/inspirationPage/tabicons/plane_w.svg';
import AccommodationIconWhite from '@/assets/inspirationPage/tabicons/accommodation_w.svg';
import ActivitiesIconWhite from '@/assets/inspirationPage/tabicons/activities_w.svg';
import NightlifeIconWhite from '@/assets/inspirationPage/tabicons/nightlife_w.svg';
import RestaurantIconWhite from '@/assets/inspirationPage/tabicons/restaurant_w.svg';
import TapToViewButton from '@/views/components/TapToViewButton.vue';
import PrimaryButton from '@/views/components/PrimaryButton.vue';
import DesktopGallerySlider from '@/views/destinationFeed/desktop/DesktopGallerySlider.vue';
import ArrowRight from '@/assets/destinationPage/icons/arrow_right.svg';
import ArrowLeft from '@/assets/destinationPage/icons/arrow_left.svg';
import MapPin from '@/assets/inspirationPage/map-pin.svg';
import { mapActions, mapGetters, mapState } from 'vuex';
import OffersContainer from './OffersContainer.vue';
import Story from './insta-story/InstaStory.vue';
import FlatButton from './FlatButton';
import topCard from './cards/topCard.vue';
import OfferCardSmall from './tab-items/OfferCardSmall.vue';
import HotelCardSmall from './tab-items/HotelCardSmall.vue';
import moment from 'moment';
//import { convertToDatePickerFormat } from '@/utils/appDate.js';
import ExpandStoryCarousel from './ExpandStoryCarousel';
import Breadcrumbs from '../layouts/common/Breadcrumbs.vue';
import axios from 'axios';

export default {
  name: 'InspirationPage',
  components: {
    topCard,
    CartIcon,
    InstagramIcon,
    FlatButton,
    FlightIcon,
    AccommodationIcon,
    ActivitiesIcon,
    NightlifeIcon,
    RestaurantIcon,
    FlightIconWhite,
    AccommodationIconWhite,
    ActivitiesIconWhite,
    NightlifeIconWhite,
    RestaurantIconWhite,
    OffersContainer,
    Story,
    HotelCardSmall,
    TapToViewButton,
    PrimaryButton,
    DesktopGallerySlider,
    ArrowLeft,
    ExpandStoryCarousel,
    ArrowRight,
    MapPin,
    OfferCardSmall,
    Breadcrumbs,
  },

  watch: {
    expandStoryCarouselEnabled: function() {
      if (this.expandStoryCarouselEnabled) {
        document.querySelector('html')?.classList.add('hotel-modal-open');
      } else {
        document.querySelector('html')?.classList.remove('hotel-modal-open');
      }
    },
  },
  data() {
    return {
      showTipsAndTricks: false,
      cart: [],
      pickedHotel: null,
      expandDescription: false,
      tabIndexMap: new Map([
        [ 'flights', 0 ],
        [ 'accommodation', 1 ],
        [ 'activities', 2 ],
        [ 'restaurants', 3 ],
        [ 'events', 4 ],
      ]),
      displayExperiencesIndex: 0,
      expandStoryCarouselEnabled: false,
      availableTabs: [
        {
          id: 'flights',
          label: 'Flights',
          icon: FlightIcon,
          iconWhite: FlightIconWhite,
        },
        {
          id: 'accommodation',
          label: 'Accommodation',
          icon: AccommodationIcon,
          iconWhite: AccommodationIconWhite,
        },
        {
          id: 'activities',
          label: 'Activities',
          icon: ActivitiesIcon,
          iconWhite: ActivitiesIconWhite,
        },
        {
          id: 'restaurants',
          label: 'Restaurants',
          icon: RestaurantIcon,
          iconWhite: RestaurantIconWhite,
        },
        {
          id: 'events',
          label: 'Events',
          icon: NightlifeIcon,
          iconWhite: NightlifeIconWhite,
        },
      ],
      activeTab: 'accommodation',
      settings: {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
      },
      readMoreActivated: false,
      filteredExperiences: [],
      currentExperienceId: '',
      index: 0,
      loading: false,
      nightsCount: 1,
      roomsCount: 1,
      adultsCount: 1,
      childrenCount: 0,
      cities: [],
    };
  },
  created() {
    if (this.$route.query) {
      this.$store.commit('search/setParams', {
        ...this.$route.query,
        maxPrice: parseFloat(this.$route.query.maxPrice),
      });
    }
  },
  async mounted() {
    try {
      this.$store.commit('setAvailabilityHotels', []);
      await this.loadExperience(this.$route.params.inspirationId);

      this.searchFlightsRequest();
      this.cities = await axios.get(
        'https://travelo-91156-default-rtdb.europe-west1.firebasedatabase.app/destination_cities.json'
      );
      this.findHotels(this.cities.data[this.experience.city_id]);
    } catch (e) {
      console.log(e);
      this.$router.push({ name: 'LandingPage' });
    }
    this.pickedHotel = await this.loadPickedHotel({
      from: this.from,
      to: this.to,
      nightsCount: this.nightsCount,
      roomsCount: this.roomsCount,
      adultsCount: this.adultsCount,
      childrenCount: this.childrenCount,
      paxes: [],
    });

    //const { cityName } = this.$route.params;
    // load inital exp

    this.currentExperienceId = this.experience.exp_id;
  },
  computed: {
    ...mapGetters([
      'experience',
      'experiences',
      'cityaccommodation',
      'destinationCities',
    ]),

    hotelTabItemRef() {
      return this.$refs.offers.$refs.accommodations;
    },
    buyFullPackageImage() {
      return {
        backgroundImage:
          this.experience &&
          'url(' + this.experience.influencer_data.image + ')',
      };
    },
    city: {
      get() {
        try {
          return this.cities.data[this.experience.city_id];
        } catch (err) {
          return null;
        }
      },
    },
    storyItems() {
      return this.experience.story_array.map(item => {
        const isVideo = item.includes('.mov?') || item.includes('.mp4?');
        return {
          url: item,
          type: isVideo ? 'video' : 'image',
        };
      });
    },
    displayExperiences: function() {
      let restaurants = this.experience.restaurants
        .filter(value => value.description !== '')
        .map(obj => ({
          ...obj,
          type: 'restaurants',
        }));

      let activities = this.experience.activities
        .filter(value => value.description !== '')
        .map(obj => ({
          ...obj,
          type: 'activities',
        }));

      let combinedArray = [];

      for (let i = 0; i < restaurants.length; i += 1) {
        combinedArray.push(restaurants[i]);
        if (i % 2 == 0) {
          if (activities.length > 0) {
            combinedArray.push(activities.pop());
          }
        }
      }

      combinedArray = [ ...combinedArray, ...activities ];

      if (this.pickedHotel) {
        combinedArray = [ this.pickedHotel, ...combinedArray ];
      }

      return combinedArray.filter((it, i) => {
        return (
          i <= 2 + this.displayExperiencesIndex &&
          i >= this.displayExperiencesIndex
        );
      });
    },
    currentExperience() {
      return this.filteredExperiences.find(
        experience => experience.exp_id === this.currentExperienceId
      );
    },
    currentExperienceIndex() {
      return this.filteredExperiences.findIndex(
        experience => experience.exp_id === this.currentExperienceId
      );
    },
    ...mapState({
      startDate: state => state.search.from,
      endDate: state => state.search.to,
    }),
  },
  methods: {
    ...mapActions([
      'loadExperience',
      'loadExperiences',
      'loadHotelByCode',
      'loadAvailabilityHotels',
    ]),
    async loadPickedHotel(searchData) {
      const code = parseInt(this.experience.hotelbeds_code);
      if (code === null) {
        return;
      }
      var loadedHotel = await this.loadHotelByCode({
        hotels: {
          hotel: [ code ],
        },
        checkInDate:
          searchData.from || moment().add(30, 'd').format('YYYY-MM-DD'),
        checkOutDate:
          searchData.to || moment().add(33, 'd').format('YYYY-MM-DD'),
        nightsCount: searchData.nightsCount,
        roomsCount: searchData.roomsCount,
        adultsCount: searchData.adultsCount,
        childrenCount: searchData.childrenCount,
        paxes: searchData.paxes,
      });
      loadedHotel['hotel'] = true;

      return loadedHotel;
    },
    ...mapActions('flights', [ 'searchFlights' ]),
    feedbackHandler() {
      window.open(
        `https://4zbsmiafid4.typeform.com/to/rcLpS7Ns?#experience=${this.experience.exp_id}`,
        '_blank'
      );
    },
    setActiveTab(activeTab) {
      this.activeTab = activeTab;
    },
    story() {
      return {
        next: () => this.$refs.story.$refs.carousel.nextSlide(),
        prev: () => this.$refs.story.$refs.carousel.prevSlide(),
      };
    },
    showInstagram() {
      window.open(
        'https://instagram.com/' + this.experience.influencer_data.influencer_id
      );
    },
    handleCityPage() {
      this.$router.push({
        path: '/destination/' + this.city.name,
        query: { mode: 'Booking' },
      });
    },
    prevSlide() {
      if (this.currentExperienceIndex === 0) {
        return;
      }
      this.currentExperienceId =
        this.filteredExperiences[this.currentExperienceIndex - 1].exp_id;
    },
    nextSlide() {
      if (this.currentExperienceIndex >= this.filteredExperiences.length - 1) {
        return;
      }
      this.currentExperienceId =
        this.filteredExperiences[this.currentExperienceIndex + 1].exp_id;
    },
    async searchFlightsRequest() {
      this.loading = true;
      const payload = {
        fly_from: 'Berlin',
        fly_to: this.experience.flight_destination,
        departure_date: this.from || moment().add('d', 30).format('YYYY-MM-DD'),
        return_date: this.to || moment().add('d', 33).format('YYYY-MM-DD'),
        max_stopovers: 10,
        max_fly_duration: 50,
      };
      try {
        this.flights = await this.searchFlights(payload);
      } catch (e) {
        console.log(e.message);
      } finally {
        this.loading = false;
      }
    },
    async findHotels(city) {
      this.$store.commit('setIsNoContent', false);

      await this.loadAvailabilityHotels({
        geolocation: {
          latitude: city.coordinates_lat,
          longitude: city.coordinates_lng,
          radius: 50,
          unit: 'km',
        },
        checkInDate: this.from || moment().add(30, 'd').format('YYYY-MM-DD'),
        checkOutDate: this.to || moment().add(33, 'd').format('YYYY-MM-DD'),
        nightsCount: this.nightsCount,
        roomsCount: this.roomsCount,
        adultsCount: this.adultsCount,
        childrenCount: this.childrenCount,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/variables.scss';

.storycontainer {
  width: calc(36.76% - 64px);
  min-width: calc(36.76% - 64px);
  margin-right: 64px;
  user-select: none;
  @media (max-width: 760px) {
    min-width: unset;
    width: calc(100vw - 92px);
  }
}

.experience {
  color: #28262e;
  width: calc(63.24%);
  @media (max-width: 760px) {
    width: 100%;
  }
  h1 {
    margin-top: 12px;
    padding-right: 96px;

    @media (max-width: 760px) {
      padding-top: 56px;
    }
  }
}

.location-pin {
  background-color: $secondary;
  padding-top: 8px;
  //padding-top: 8px;
  padding-left: 8px;
}

.location-padding {
  color: $primary;
  background-color: $secondary;
  padding: 11px 8px;
  padding-right: 12px;
}

.chevron-buttons {
  width: 20%;
  //max-width: 921px;
}

.top-container {
  width: calc(100% - 88px);
  max-width: 1624px;
  margin: auto;
  position: relative;
  flex-direction: row;
  @media (max-width: 760px) {
    flex-direction: column;
  }
}

.buyseparately {
  @apply tw-mt-12;
  @include respond-to(lg) {
    @apply tw-mt-7;
  }
}

.desktopSlider {
  max-width: 72rem;
}
.tips-and-tricks {
  @apply tw-font-sans tw-text-gray-700 tw-font-bold;
}

.label {
  user-select: none;
  margin: auto 0.5rem;
  font-size: 0.75vw;
}

.tab-list {
  @apply tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-0 tw-mt-12 tw-border tw-rounded-full tw-px-2;
  max-width: 52vw;
  width: 100%;
  margin-bottom: 56px;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
}

.header-section {
  //padding-top: 120px;

  & > div:first-child {
    margin-top: 20px;
  }

  .left-column {
    @screen md {
      @apply tw-w-1/2 tw-pl-28 tw-pt-20;
    }

    .avatar {
      box-shadow: 0px 0px 5px black;
      border-radius: 0px;
      width: 54px;
      height: 54px;
    }

    .action-bar {
      flex-grow: 0.5;
    }
  }

  .right-column {
    .story {
      box-shadow: 0 0 4px black;
      border-radius: 10px;
    }
  }
}

.name {
  font-family: 'PoppinsSemiBold';
  font-size: 2.292vw;
  line-height: 2.4vw;
  letter-spacing: -0.04em;
  color: #28262e;
  margin-bottom: 16px;
}

.ownTrip {
  font-size: 4vw;
}

.readmore {
  font-family: 'MontserratBold';
  font-size: 18px;
  color: #747378 !important;
  line-height: 28px;
}

.avatar {
  border-radius: 40px;
  width: 64px;
  height: 64px;
}

.infName {
  font-family: 'MontserratSemiBold';
  font-size: 18px;
}

.addItemButton {
  border-radius: 102px !important;
}

.instagramIcon {
  background-color: #eaeaeb;
}
.link {
  display: block;

  @include respond-to(xs-sm) {
    display: flex;
    align-items: center;
  }

  .icon-container {
    color: #28262e;
    width: 10vw;
  }

  &.active {
    .icon-container {
      background: #d62a53;
      color: white;
    }
    .label {
      color: white;
    }
  }

  button {
    color: currentColor;
    width: 48px;

    svg {
      width: 20px;
      height: 48px;
      margin: auto;
      margin-left: 0.5rem;
    }
  }
}

li {
  padding: 0;
}

.back-button-container {
  margin-bottom: 38px;
  padding: 0 44px;
}

.description {
  font-family: 'MontserratMedium';

  font-size: 1vw;
  line-height: 1.2vw;

  color: #747378;
  max-width: 50vw;
  mix-blend-mode: normal;
  margin-bottom: 20px;
}
.influencer-image {
  width: 73.11px;
  height: 73.11px;
  border-radius: 50%;
  object-fit: cover;
  user-select: none;
}
.influencer-name {
  font-family: PoppinsMedium;
  font-size: 0.9vw;
  line-height: 1vw;
  letter-spacing: -0.04em;
  color: #28262e;

  mix-blend-mode: normal;
  margin-top: 1rem;
  margin-bottom: 1px;
}
.influencer-pick {
  font-family: PoppinsSemiBold;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -0.04em;
  color: #28262e;

  mix-blend-mode: normal;
  margin-top: 5px;
  margin-bottom: 22px;
}
.influencer-influencer-id {
  font-family: MontserratSemiBold;
  margin-top: 2px;
  font-size: 0.6vw;
  line-height: 0.75vw;

  letter-spacing: -0.04em;

  color: #747378;

  mix-blend-mode: normal;
}

.see-more {
  font-family: MontserratBold;
  cursor: pointer;
  font-size: 18px;
  line-height: 28px;
  user-select: none;
  color: #747378;

  mix-blend-mode: normal;
}

.cards-list-container {
  position: relative;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  @media (max-width: 1564px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @include respond-to(xl) {
    gap: 60px;
  }
  gap: 14px;
  min-height: 332px;
  max-height: 332px;
  height: 332px;
  width: 100%;
  min-width: 100%;
  //max-width: 921px;

  .card-container {
    margin-bottom: 24px;
    max-width: calc(33.33% - 9.33px);
    @media (max-width: 1564px) {
      max-width: calc(50% - 9.33px);
      &:nth-child(3) {
        display: none;
      }
    }
  }
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .cards-list-container {
      .card-container {
        margin-bottom: 24px;
        max-width: calc(33.33% - 19.33px);
        margin-right: 14px !important;
        @include respond-to(xl) {
          margin-right: 60px;
        }
        @media (max-width: 1564px) {
          max-width: calc(50% - 19.33px);
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}

.theme--light.v-icon {
  color: black !important;
}
</style>
