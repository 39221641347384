<template>
  <div class="gallery-slider tw-h-80">
    <template v-if="filteredExperiences && filteredExperiences.length > 0">
      <splide ref="slider" :options="options">
        <template v-for="experience in filteredExperiences">
            <splide-slide :key="experience.exp_id" class="gallery-wrapper">
              <DesktopGalleryCard :experience="experience"  @click="$emit('cardChanged', experience.exp_id)" :currentExperienceId="currentExperienceId" />
            </splide-slide>
        </template>
      </splide>
    </template>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import DesktopGalleryCard from '@/views/destinationFeed/desktop/DesktopGalleryCard.vue';

export default {
  name: 'DesktopGallerySlider',
  components: {
    Splide,
    SplideSlide,
    DesktopGalleryCard,
  },
  props: {
    filteredExperiences: Array,
    currentExperienceId: String,
  }, 
  data() {
    return {
      interval: undefined,
      options: {
        width: '100%',
        perPage: 2,
        perMove: 1,
        pagination: false,
        arrows: false,
      },
    };
  },
  computed: {
    currentExperienceIndex() {
      return this.filteredExperiences.findIndex(experience => experience.exp_id === this.currentExperienceId);
    }
  },
  watch: {
    currentExperienceId: function() {
      this.$refs.slider.go(this.currentExperienceIndex);
    }
  },
  methods: {
    stopTimer() {
      clearInterval(this.interval);
    },
    startTimer() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      this.interval = setInterval(() => {
        this.nextSlide();
      }, 2000);
    },
    nextSlide() {
      this.startTimer();

      if (!this.$refs.carousel) {
        return;
      }

      if (this.slide >= this.$refs.carousel.slideCount - 2) {
        this.slide = 1;
      } else {
        this.slide += 2;
      }
    },
    prevSlide() {
      this.startTimer();

      if (!this.$refs.carousel) {
        return;
      }

      if (this.slide < 2) {
        this.slide = this.$refs.carousel.slideCount - 2;
      } else {
        this.slide -= 2;
      }
    },
  },
  mounted() {
    this.startTimer();
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
.gallery-wrapper {
    width: 20rem !important;
    
}
.gallery-slider::v-deep {
  .splide__container{
    position:relative;
    box-sizing:content-box
  }
  .splide__list{
    margin:0!important;
    padding:0!important;
    width:-webkit-max-content;
    width:max-content;
    will-change:transform
  }
  .splide.is-active .splide__list{
    display:flex
  }
  .splide__pagination{
    display:inline-flex;
    align-items:center;
    width:95%;
    flex-wrap:wrap;
    justify-content:center;
    margin:0
  }
  .splide__pagination li{
    list-style-type:none;
    display:inline-block;
    line-height:1;
    margin:0
  }
  .splide{
    visibility:hidden
  }
  .splide,.splide__slide{
    position:relative;
    outline:none
  }
  .splide__slide{
    box-sizing:content-box;
    list-style-type:none!important;
    margin:0;
    flex-shrink:0;
  }
  .splide__slide img{
    vertical-align:bottom
  }
  .splide__slider{
    position:relative
  }
  .splide__spinner{
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;
    display:inline-block;
    width:20px;
    height:20px;
    border-radius:50%;
    border:2px solid #999;
    animation:splide-loading 1s linear infinite
  }
  .splide__track{
    position:relative;
    z-index:0;
    overflow:hidden
  }
  .splide--draggable>.splide__track>.splide__list>.splide__slide{
    -webkit-user-select:none;
    user-select:none
  }
  .splide--fade>.splide__track>.splide__list{
    display:block
  }
  .splide--fade>.splide__track>.splide__list>.splide__slide{
    position:absolute;
    top:0;
    left:0;
    z-index:0;
    opacity:0
  }
  .splide--fade>.splide__track>.splide__list>.splide__slide.is-active{
    position:relative;
    z-index:1;
    opacity:1
  }
  .splide--rtl{
    direction:rtl
  }
  .splide--ttb>.splide__track>.splide__list{
    display:block
  }
  .splide--ttb>.splide__pagination{
    width:auto
  }
  .splide__arrow{
    position:absolute;
    z-index:1;
    top:50%;
    transform:translateY(-33%);
    border:none;
    padding:0;
    background:transparent
  }
  .splide__arrow svg{
    width:2.5em;
    height:2.5em;
    fill:#FFF;
    transition:fill .2s linear
  }
  .splide__arrow:hover{
    cursor:pointer
  }
  .splide__arrow:hover svg{
    fill:#999
  }
  .splide__arrow:focus{
    outline:none
  }
  .splide__arrow--prev{
    left:0em
  }
  .splide__arrow--prev svg{
    transform:scaleX(-1)
  }
  .splide__arrow--next{
    right:0em
  }
  .splide__slider>.splide__arrows .splide__arrow--prev{
    left:-2.5em
  }
  .splide__slider>.splide__arrows .splide__arrow--next{
    right:-2.5em
  }
  .splide__pagination{
    position:absolute;
    z-index:1;
    bottom:1em;
    left:50%;
    transform:translateX(-33%);
    padding:0
  }
  .splide__pagination__page{
    display:inline-block;
    width:20px;
    height:5px;
    background:#ccc;
    border-radius:2.5px;
    margin:3px;
    padding:0;
    transition:all .2s linear;
    border:none
  }
  .splide__pagination__page.is-active{
    background:#999
  }
  .splide__pagination__page:hover{
    cursor:pointer;
    background:#ccc
  }
  .splide__pagination__page:focus{
    outline:none
  }
  .splide__progress__bar{
    width:0;
    height:3px;
    background:#999
  }
  .splide{
    padding: 0;
  }
  .splide__slide{
    border-radius:4px
  }
  .splide__spinner{
    border:2px solid #999;
    border-left-color:transparent
  }
  .splide--nav>.splide__track>.splide__list>.splide__slide{
    border:3px solid #fff;
    cursor:pointer;
    opacity:.7;
    border-radius:4px
  }
  .splide--nav>.splide__track>.splide__list>.splide__slide.is-active{
    border-color:#999;
    opacity:1
  }
  .splide--nav>.splide__track>.splide__list>.splide__slide:focus{
    outline:none
  }
  .splide--rtl>.splide__arrows .splide__arrow--prev,.splide--rtl>.splide__track>.splide__arrows .splide__arrow--prev{
    right:1em;
    left:auto
  }
  .splide--rtl>.splide__arrows .splide__arrow--prev svg,.splide--rtl>.splide__track>.splide__arrows .splide__arrow--prev svg{
    transform:scaleX(1)
  }
  .splide--rtl>.splide__arrows .splide__arrow--next,.splide--rtl>.splide__track>.splide__arrows .splide__arrow--next{
    left:1em;
    right:auto
  }
  .splide--rtl>.splide__arrows .splide__arrow--next svg,.splide--rtl>.splide__track>.splide__arrows .splide__arrow--next svg{
    transform:scaleX(-1)
  }
  .splide--ttb>.splide__arrows .splide__arrow,.splide--ttb>.splide__track>.splide__arrows .splide__arrow{
    left:50%;
    transform:translate(-50%)
  }
  .splide--ttb>.splide__arrows .splide__arrow--prev,.splide--ttb>.splide__track>.splide__arrows .splide__arrow--prev{
    top:1em
  }
  .splide--ttb>.splide__arrows .splide__arrow--prev svg,.splide--ttb>.splide__track>.splide__arrows .splide__arrow--prev svg{
    transform:rotate(-90deg)
  }
  .splide--ttb>.splide__arrows .splide__arrow--next,.splide--ttb>.splide__track>.splide__arrows .splide__arrow--next{
    top:auto;
    bottom:1em
  }
  .splide--ttb>.splide__arrows .splide__arrow--next svg,.splide--ttb>.splide__track>.splide__arrows .splide__arrow--next svg{
    transform:rotate(90deg)
  }
  .splide--ttb>.splide__pagination{
    display:flex;
    flex-direction:column;
    bottom:50%;
    left:auto;
    right:1em;
    transform:translateY(50%)
  }
  .splide--ttb>.splide__pagination .splide__pagination__page{
    width:5px;
    height:20px
  }

  .splide__arrow:disabled {
    display: none !important;
  }

}

</style>
