var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outer",class:{ enabled: _vm.enabled }},[_c('div',{staticClass:"overlay",on:{"click":() => {
        if (_vm.isExpressCheckout) {
          _vm.exitView();
        } else {
          _vm.$emit('toggle-expand');
        }
      }}}),(_vm.enabled)?[_c('div',{staticClass:"button prev",on:{"click":() => {
          _vm.$emit('prev-story-slide');
        }}},[_c('PrevIcon')],1)]:_vm._e(),_c('div',{staticClass:"inner"},[_vm._t("default")],2),(_vm.enabled)?[_c('div',{staticClass:"button next",on:{"click":() => {
          _vm.$emit('next-story-slide');
        }}},[_c('NextIcon')],1)]:_vm._e(),(_vm.enabled && !_vm.isExpressCheckout)?_c('div',{staticClass:"button close",on:{"click":() => {
        _vm.$emit('toggle-expand');
      }}},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M18 6L6 18","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6 6L18 18","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e(),(_vm.isExpressCheckout)?_c('div',{staticClass:"button close",on:{"click":_vm.exitView}},[_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M18 6L6 18","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6 6L18 18","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }